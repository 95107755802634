import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.isLoginService()) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }

  canActivateChild(): boolean {
    if (!this.auth.isLoginService()) {
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }

}
