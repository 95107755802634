import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';

export class Request {
  resource: String;
  data?: Object;
  type: String;

  constructor(_resource: string, _data: Object, _type: String) {
    this.resource = _resource;
    this.data = _data;
    this.type = _type;
  }
}

export class Method extends Request {

}

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  AuthserviceUrl = environment.authBaseUrl;
  private getuserTokenCounter = 0;
  private getuserToken: Promise<any>;


  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) { }


  public callService(request: Request) {

    return Observable.fromPromise(new Promise(async (resolve, reject) => {
      try {
        const checkToken = this.checkUserToken();
        const response = await this.callServiceRequest(request);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }));

  }

  private getUrl(resource: String) {
    return environment.api.baseUrl + resource;
  }

  private callServiceRequest(request: Request): Promise<any> {
    let requestobj;

    switch (request.type) {
      case 'post':
        requestobj = this.http.post(this.getUrl(request.resource), request.data, this.getRequestOptions());
        break;
      case 'put':
        requestobj = this.http.put(this.getUrl(request.resource), request.data, this.getRequestOptions());
        break;
      case 'patch':
        requestobj = this.http.patch(this.getUrl(request.resource), request.data,  this.getRequestOptions());
        break;
      case 'delete':
        requestobj = this.http.delete(this.getUrl(request.resource), this.getRequestOptions());
        break;
      default:
        requestobj = this.http.get(this.getUrl(request.resource), this.getRequestOptions());
        break;
    }
    return new Promise((resolve, reject) => {

      requestobj.subscribe(response => {
          resolve(response);
        }
        , error => {
          if (error.status === 401) {
            if (this.authService.isLoginService()) {
              this.authService.clearLocalStorage();
              this.getUserToken();
            } else {
              this.router.navigateByUrl('/login');
            }
          } else {
            reject(error);
          }
        }
      );
    });
  }

  private checkUserToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.authService.isLoginService()) {
        return this.getUserToken().then(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }


  // private getuserTokenRefreshCounter: number = 0;
  // private getuserTokenRefresh: Promise<any>;

  // private getUserTokenRefresh(): Promise<any> {
  //     this.getuserTokenRefreshCounter++;
  //     if (!this.getuserTokenRefresh)
  //         this.getuserTokenRefresh = new Promise((resolve, reject) => {
  //             this.http.post(this.AuthserviceUrl + "/" + "RefreshToken",
  //                 {"RefreshToken": window.localStorage.getItem("AuthUser_RefreshToken")})
  //                 .subscribe(response =>
  //                         resolve(response)
  //                     , error => reject());
  //         }).then(response => {
  //             this.authService.logInService("Bearer " + response["access_token"], String(new Date().getTime() + (response["expires_in"] * 10000)), response["refresh_token"]);
  //         });
  //     return this.getuserTokenRefresh;
  // }





  private getUserToken() {
    this.getuserTokenCounter++;
    if (!this.getuserToken) {
      this.getuserToken = new Promise((resolve, reject) => {
        this.http.post(this.AuthserviceUrl, '', this.getRequestOptions())
          .subscribe(function (response) {
              // response istenilen durumda değilse yani istenilen veriler yoksa 500 sayfasına yönlendir
              if (response && response['access_token'] && response['expires_in']) {
                resolve(response);
              } else {
                // this.router.navigate(['/error/_500']);
              }
            },
            (error) => {
              // burada zaten servis gitmiş 500 sayfasına yönlendir
              // this.router.navigate(['/error/_500']);
            }
          );
      }).then(response => {
        this.authService.logInService('Bearer ' + response['access_token'], String(new Date().getTime() + (response['expires_in'] * 10000)), null);
      });
    }
    return this.getuserToken;
  }

  private getRequestOptions() {
    return { headers: this.getHeaders() };
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    if (this.storageService.getFromLocalStorage('AuthUserVEI')) {
      headers = headers.append('Authorization',  'Bearer ' + this.storageService.getFromLocalStorage('AuthUserVEI'));
      headers = headers.append('Content-Type', 'application/json');
    } else {
      // headers = headers.append('Authorization', 'Bearer ');
      headers = headers.append('Content-Type', 'application/json');
    }

    return headers;
  }


}
