import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {GeneralService, Method} from '../shared/services/general.service';
import {StorageService} from '../shared/services/storage.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('lForm') loginForm: NgForm;
  gebruikersnaam = 's.altin@ortimo.com';
  wachtwoord = 'admin';
  sendLoginRequest = false;

  constructor(public router: Router,
              private service: GeneralService,
              private storageService: StorageService ) { }

  ngOnInit() {
  }

  onLoggedin() {
    this.sendLoginRequest = true;
    const body = {
      grant_type: 'password',
      client_id: 2,
      client_secret: 'K5hgyA69NL1ByZXzsSlWpoGNrx1JoYM73qB645CF',
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    };
    console.log(body);

    this.service.callService(new Method(environment.api.methods.login.getLoginCredentials, body, 'post')).subscribe((response) => {
      console.log(response);
      this.storageService.setLocalStorage('AuthUserVEI', response['access_token']);
      this.sendLoginRequest = false;
      this.router.navigate(['starter']);
    });

  }
}
