import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getFromLocalStorage(key: string) {
    return window.localStorage.getItem(key);
  }

  setLocalStorage(key: string, value: any) {
    return window.localStorage.setItem(key, value);
  }
  RemoveLocalStorage(key: string) {
    return window.localStorage.removeItem(key);
  }
}
