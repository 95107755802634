import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private storageService: StorageService) { }



  private SessionKey = 'AuthUserVEI';
  private SessionExpireKey = 'AuthUser_ExpireEndTimeStamp';
  private SessionRefreshKey = 'AuthUser_RefreshToken';

  isLoginService() {
    const authUser = this.storageService.getFromLocalStorage(this.SessionKey);
    if (authUser) { return true; } else { return false; }
  }

  isLoginServiceExpire() {
    // if api is not responding
    return true;
    // =========================
    // if (this.isLoginService()) {
    //     //
    //     //   const usertoken_endTime = new Number(this.storageService.getFromLocalStorage(this.SessionExpireKey));
    //     //   const now = new Date().getTime();
    //     //
    //     //   if (usertoken_endTime && usertoken_endTime > now) {
    //     //     return false;
    //     //   } else {
    //     //     return true;
    //     //   }
    //     // } else { return false; }
  }


  logInService(token: string, expireTimeStamp: string, refreshKey: string) {

    this.storageService.setLocalStorage(this.SessionKey, token);
    this.storageService.setLocalStorage(this.SessionExpireKey, expireTimeStamp);
    this.storageService.setLocalStorage(this.SessionRefreshKey, refreshKey);
  }

  logIn(token: string, expireTimeStamp: string, refreshKey: string) {
    this.storageService.setLocalStorage(this.SessionKey, token);
    this.storageService.setLocalStorage(this.SessionExpireKey, expireTimeStamp);
    this.storageService.setLocalStorage(this.SessionRefreshKey, refreshKey);
  }

  clearLocalStorage() {
    this.storageService.RemoveLocalStorage(this.SessionKey);
    this.storageService.RemoveLocalStorage(this.SessionExpireKey);
    this.storageService.RemoveLocalStorage(this.SessionRefreshKey);
  }

}
