// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  authBaseUrl: 'https://vragen-api.otoerp.com/',
  api: {
    baseUrl: 'https://vragen-api.otoerp.com/',
    methods: {
      login: {
        getLoginCredentials: 'oauth/token'
      },
      currenuser: {
        getCurrentUser: 'api/user'
      },
      categories: {
        getAllCategories: 'api/categories',
        getOneCategory: 'api/categories/', // + id of category
        addcategory: 'api/categories',
        updateCategory: 'api/categories/', // + id of category
        deleteCategory: 'api/categories/' // + id of category
      },
      author: {
        getAllAuthors: 'api/authors',
        deleteAuthor: 'api/authors/', // AuthorID
        getOneAuthor: 'api/authors/', // AuthorID
        editAuthor: 'api/authors/', // AuthorID
        addAuthor: 'api/authors'
      },
      answerQuestion: {
        questionAnswered: 'api/users/', // userID + /results
        getAllResultsOneUser: 'api/users/' // userID + /results
      },
      questions: {
        getAllQuestions: 'api/questions',
        getOneQuestion: 'api/questions/',
        updateOneQuestion: 'api/questions/',
        addQuestion: 'api/questions',
      },
      articles: {
        getAllArticles: 'api/articles',
        getOneArticle: 'api/articles/',
        updateOneArticle: 'api/articles/',
        addArticle: 'api/articles',
      },
      quotes: {
        getAllQuotes: 'api/quotes',
        getOneQuotes: 'api/quotes/',
        updateOneQuote: 'api/quotes/',
        addQuote: 'api/quotes'
      }
    }
  }
};
