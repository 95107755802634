import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import {AuthGuardService} from './shared/services/auth-guard.service';
import {LoginComponent} from './login/login.component';

export const Approutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'categorieen',
        loadChildren: './categories/categorieen.module#CategorieenModule'
      },
      {
        path: 'auteur',
        loadChildren: './author/author.module#AuthorModule'
      },
      {
        path: 'questions',
        loadChildren: './questions/questions.module#QuestionsModule'
      },
      {
        path: 'articles',
        loadChildren: './articles/articles.module#ArticlesModule'
      },
      {
        path: 'quotes',
        loadChildren: './quotes/quotes.module#QuotesModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
