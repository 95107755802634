import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from './loading.service';
@Component({
  selector: 'app-core-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  show = false;
  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.getLoadingStatus()
      .subscribe((statusType: boolean) => {
        this.show = statusType;
        console.log(this.show);
      });
  }

  setLoadingBar() {
  }

  ngOnDestroy() {
    // this.loadingService.loadingStateChange.unsubscribe();
  }
}
